import endpoints from './endpoints';
import {fetch} from '../../lib/fetch';
import {serviceRequestLastFetch} from '~/actions';

const service_request = {
  get: async (request_id, dispatch) => {
    const result = await fetch(`${endpoints.service_request}/${request_id}`);

    if (!result) {
      return null;
    }

    dispatch(serviceRequestLastFetch(request_id));

    const json = await result.json();
    return json;
  },
  delete: async (request_id) => {
    const result = await fetch(`${endpoints.service_request}/${request_id}`, {
      method: 'DELETE',
    });

    if (!result) {
      return null;
    }

    const json = await result.json();
    return json;
  },
  has_maintenance: async (request_id) => {
    const result = await fetch(
      `${endpoints.service_request}/${request_id}/has_maintenance`,
    );

    if (!result) {
      return {
        success: false,
        result: null,
      };
    }

    const json = await result.json();
    return json;
  },
  maintenance: async (request_id) => {
    const result = await fetch(
      `${endpoints.service_request}/maintenance/${request_id}`,
    );

    if (!result) {
      return null;
    }

    const json = await result.json();
    return json;
  },
  update_maintenance_task: async (request_id, changes) => {
    const result = await fetch(
      `${endpoints.service_request}/maintenance/${request_id}`,
      {
        method: 'PATCH',
        body: JSON.stringify(changes),
      },
    );

    if (!result) {
      return null;
    }

    const json = await result.json();
    return json;
  },
  requests: async () => {
    const result = await fetch(endpoints.service_request);

    if (!result) {
      return null;
    }

    const json = await result.json();
    return json;
  },
  post: async (service_request) => {
    const result = await fetch(`${endpoints.service_request}`, {
      method: 'POST',
      body: JSON.stringify({
        ...service_request,
      }),
    });

    if (!result) {
      return null;
    }

    const json = await result.json();
    return json;
  },
  patch: async (
    request_id,
    {
      datetime,
      mileage,
      client,
      phone_number,
      email,
      replacement,
      invoice_number,
      vin_part,
      vin_number,
      assigned_user_id,
    },
  ) => {
    const result = await fetch(`${endpoints.service_request}/${request_id}`, {
      method: 'PATCH',
      body: JSON.stringify({
        datetime,
        mileage,
        client,
        phone_number,
        email,
        replacement,
        invoice_number,
        vin_part,
        vin_number,
        assigned_user_id,
      }),
    });

    if (!result) {
      return null;
    }

    const json = await result.json();
    return json;
  },
  component: async (request_id, component_id) => {
    const result = await fetch(
      `${endpoints.service_request}/${request_id}/component/${component_id}`,
    );

    if (!result) {
      return null;
    }

    const json = await result.json();
    return json;
  },
  task: async (request_id, task_id) => {
    const result = await fetch(
      `${endpoints.service_request}/${request_id}/task/${task_id}`,
    );

    if (!result) {
      return {success: false, result: {}};
    }

    const json = await result.json();
    return json;
  },
  create_task: async (
    request_id,
    {
      component_id,
      work_id,
      rob_code,
      description,
      number,
      amount,
      duration,
      type,
    },
  ) => {
    const result = await fetch(
      `${endpoints.service_request}/${request_id}/task`,
      {
        method: 'POST',
        body: JSON.stringify({
          component_id,
          work_id,
          rob_code,
          description,
          number,
          amount,
          duration,
          type,
        }),
      },
    );

    if (!result) {
      return null;
    }

    const json = await result.json();
    return json;
  },
  retrieve_cart: async (component_id, supplier, options = {}) => {
    const result = await fetch(
      `${endpoints.service_request}/component/${component_id}/parts/cart`,
      {
        method: 'POST',
        body: JSON.stringify({
          supplier,
          options,
        }),
      },
    );

    if (!result) {
      return null;
    }

    const json = await result.json();
    return json;
  },
  update_part: async ({id, price, service_cost, discount}) => {
    const result = await fetch(`${endpoints.service_request}/part/${id}`, {
      method: 'PATCH',
      body: JSON.stringify({
        price,
        service_cost,
        discount,
      }),
    });

    if (!result) {
      return null;
    }

    const json = await result.json();
    return json;
  },
  delete_part: async (part_id) => {
    const result = await fetch(`${endpoints.service_request}/part/${part_id}`, {
      method: 'DELETE',
    });

    if (!result) {
      return null;
    }

    const json = await result.json();
    return json;
  },
  messages: async (request_id) => {
    const result = await fetch(
      `${endpoints.service_request}/${request_id}/messages`,
    );

    if (!result) {
      return null;
    }

    const json = await result.json();
    return json;
  },
  message_read: async (request_id, message_id) => {
    const result = await fetch(
      `${endpoints.service_request}/${request_id}/message/${message_id}/read`,
      {
        method: 'POST',
      },
    );

    if (!result) {
      return null;
    }

    const json = await result.json();
    return json;
  },
  activity_read: async (request_id, activity_id) => {
    const result = await fetch(
      `${endpoints.service_request}/${request_id}/activity/${activity_id}/read`,
      {
        method: 'POST',
      },
    );

    if (!result) {
      return null;
    }

    const json = await result.json();
    return json;
  },
  send_message: async (request_id, content) => {
    const result = await fetch(
      `${endpoints.service_request}/${request_id}/messages`,
      {
        method: 'POST',
        body: JSON.stringify({
          message: {
            message: content,
          },
        }),
      },
    );

    if (!result) {
      return null;
    }

    const json = await result.json();
    return json;
  },
  send_image: async (request_id, image) => {
    const result = await fetch(
      `${endpoints.service_request}/${request_id}/messages`,
      {
        method: 'POST',
        body: JSON.stringify({
          message: {
            images: [image],
          },
        }),
      },
    );

    if (!result) {
      return null;
    }

    const json = await result.json();
    return json;
  },
  edit_message: async (request_id, message) => {
    const result = await fetch(
      `${endpoints.service_request}/${request_id}/messages`,
      {
        method: 'PATCH',
        body: JSON.stringify({
          message,
        }),
      },
    );

    if (!result) {
      return null;
    }

    const json = await result.json();
    return json;
  },

  delete_message: async (request_id, message) => {
    const result = await fetch(
      `${endpoints.service_request}/${request_id}/messages`,
      {
        method: 'DELETE',
        body: JSON.stringify({
          message,
        }),
      },
    );

    if (!result) {
      return null;
    }

    const json = await result.json();
    return json;
  },
  search_rob_code: async (request_id, prefix, options) => {
    const result = await fetch(
      `${endpoints.service_request}/${request_id}/rob_code/search`,
      {
        method: 'POST',
        body: JSON.stringify({
          prefix,
          options,
        }),
      },
    );

    if (!result) {
      return {success: false, result: null};
    }

    const json = await result.json();
    return json;
  },
  search_tires: async (request_id, prefix, options) => {
    const result = await fetch(
      `${endpoints.service_request}/${request_id}/tires/search`,
      {
        method: 'POST',
        body: JSON.stringify({
          prefix,
          options,
        }),
      },
    );

    if (!result) {
      return null;
    }

    const json = await result.json();
    return json;
  },
  search_works: async (
    request_id,
    prefix,
    options = {
      include_parts: false,
      search_rob_codes: false,
    },
  ) => {
    const result = await fetch(
      `${endpoints.service_request}/${request_id}/works/search`,
      {
        method: 'POST',
        body: JSON.stringify({
          prefix,
          options,
        }),
      },
    );

    if (!result) {
      return {success: false, result: null};
    }

    const json = await result.json();
    return json;
  },
  search_sla_tasks: async (request_id, prefix) => {
    const result = await fetch(
      `${endpoints.service_request}/${request_id}/sla_tasks/search`,
      {
        method: 'POST',
        body: JSON.stringify({
          prefix,
        }),
      },
    );

    if (!result) {
      return null;
    }

    const json = await result.json();
    return json;
  },
  car_history: async (request_id) => {
    const result = await fetch(
      `${endpoints.service_request}/${request_id}/car/history`,
    );

    if (!result) {
      return null;
    }

    const json = await result.json();
    return json;
  },
  suggestions: async (request_id) => {
    const result = await fetch(
      `${endpoints.service_request}/${request_id}/suggestions`,
    );

    if (!result) {
      return null;
    }

    const json = await result.json();
    return json;
  },
  activate_component: async (request_id, component_id, main_task_id) => {
    const result = await fetch(
      `${endpoints.service_request}/${request_id}/component/${component_id}`,
      {
        method: 'PUT',
        body: JSON.stringify({
          main_task_id,
        }),
      },
    );

    if (!result) {
      return null;
    }

    const json = await result.json();
    return json;
  },
  delete_component: async (request_id, component_id, main_task_id) => {
    const result = await fetch(
      `${endpoints.service_request}/${request_id}/component/${component_id}`,
      {
        method: 'DELETE',
        body: JSON.stringify({
          main_task_id,
        }),
      },
    );

    if (!result) {
      return null;
    }

    const json = await result.json();
    return json;
  },
  rob_codes: async (request_id) => {
    const result = await fetch(
      `${endpoints.service_request}/${request_id}/rob_codes`,
    );

    if (!result) {
      return null;
    }

    const json = await result.json();
    return json;
  },
  work_text: async (request_id, task_id) => {
    const result = await fetch(
      `${endpoints.service_request}/${request_id}/task/${task_id}/work/text`,
    );

    if (!result) {
      return {
        success: false,
        result: null,
      };
    }

    const json = await result.json();
    return json;
  },
  add_articles: async (request_id, articles) => {
    const result = await fetch(
      `${endpoints.service_request}/${request_id}/articles`,
      {
        method: 'POST',
        body: JSON.stringify({
          articles,
        }),
      },
    );

    if (!result) {
      return {success: false, result: null};
    }

    const json = await result.json();
    return json;
  },
  change_status: async (request_id, status) => {
    const result = await fetch(`${endpoints.service_request}/${request_id}`, {
      method: 'PATCH',
      body: JSON.stringify({
        status,
      }),
    });

    if (!result) {
      return {success: false, result: null};
    }

    const json = await result.json();
    return json;
  },
  sla: async (request_id) => {
    const result = await fetch(
      `${endpoints.service_request}/${request_id}/sla`,
    );

    if (!result) {
      return {
        success: false,
        result: null,
      };
    }

    const json = await result.json();
    return json;
  },
  sla_hourly_rate: async (request_id, rob_code) => {
    const result = await fetch(
      `${endpoints.service_request}/${request_id}/sla/hourly_rate${
        rob_code ? `/${rob_code}` : ''
      }`,
    );

    if (!result) {
      return {
        success: false,
        result: null,
      };
    }

    const json = await result.json();
    return json;
  },
  sla_replacement_vehicle: async (
    request_id,
    fuel,
    rental_class,
    is_estate,
    gearbox,
  ) => {
    const result = await fetch(
      `${endpoints.service_request}/${request_id}/sla/replacement_vehicle`,
      {
        method: 'POST',
        body: JSON.stringify({
          fuel,
          rental_class,
          is_estate,
          gearbox,
        }),
      },
    );

    if (!result) {
      return {
        success: false,
        result: null,
      };
    }

    const json = await result.json();
    return json;
  },
  update_tires: async (request_id, changes) => {
    const result = await fetch(
      `${endpoints.service_request}/${request_id}/tires`,
      {
        method: 'PATCH',
        body: JSON.stringify({
          changes,
          preview: false,
        }),
      },
    );

    if (!result) {
      return {
        success: false,
        result: null,
      };
    }

    const json = await result.json();
    return json;
  },
  preview_tires: async (request_id, changes) => {
    const result = await fetch(
      `${endpoints.service_request}/${request_id}/tires`,
      {
        method: 'PATCH',
        body: JSON.stringify({
          changes,
          preview: true,
        }),
      },
    );

    if (!result) {
      return {
        success: false,
        result: null,
      };
    }

    const json = await result.json();
    return json;
  },
  tires: async (request_id) => {
    const result = await fetch(
      `${endpoints.service_request}/${request_id}/tires`,
    );

    if (!result) {
      return {
        success: false,
        result: null,
      };
    }

    const json = await result.json();
    return json;
  },
  add_tasks: async (request_id, changes) => {
    const result = await fetch(
      `${endpoints.service_request}/${request_id}/tasks`,
      {
        method: 'POST',
        body: JSON.stringify({
          ...changes,
        }),
      },
    );

    if (!result) {
      return {
        success: false,
        result: null,
      };
    }

    const json = await result.json();
    return json;
  },
  update_tasks: async (request_id, changes) => {
    const result = await fetch(
      `${endpoints.service_request}/${request_id}/tasks`,
      {
        method: 'PATCH',
        body: JSON.stringify({
          ...changes,
        }),
      },
    );

    if (!result) {
      return {
        success: false,
        result: null,
      };
    }

    const json = await result.json();
    return json;
  },
  invoice: async (request_id) => {
    const result = await fetch(
      `${endpoints.service_request}/${request_id}/invoice`,
      {
        method: 'POST',
      },
    );

    if (!result) {
      return {
        success: false,
        result: null,
      };
    }

    const json = await result.json();
    return json;
  },
  duration: async (request_id) => {
    const result = await fetch(
      `${endpoints.service_request}/${request_id}/duration`,
    );

    if (!result) {
      return {
        success: false,
        result: null,
      };
    }

    const json = await result.json();
    return json;
  },
  calendar_info: async (request_id) => {
    const result = await fetch(
      `${endpoints.service_request}/${request_id}/calendar/info`,
    );

    if (!result) {
      return {
        success: false,
        result: null,
      };
    }

    const json = await result.json();
    return json;
  },
  overview: async () => {
    const result = await fetch(`${endpoints.service_request}/overview`, {
      method: 'GET',
    });

    if (!result) {
      return {
        success: false,
        result: null,
      };
    }

    const json = await result.json();
    return json;
  },
  search: async ({prefix, selectedType, selectedStatus}) => {
    const result = await fetch(`${endpoints.service_request}/overview`, {
      method: 'POST',
      body: JSON.stringify({prefix, selectedType, selectedStatus}),
    });

    if (!result) {
      return {
        success: false,
        result: null,
      };
    }

    const json = await result.json();
    return json;
  },
  status_by_license: async (license) => {
    const result = await fetch(`${endpoints.service_request}/status`, {
      method: 'POST',
      body: JSON.stringify({
        license,
      }),
    });

    if (!result) {
      return {
        success: false,
        result: null,
      };
    }

    const json = await result.json();
    return json;
  },
  change_lessor: async (request_id, lessor) => {
    const result = await fetch(`${endpoints.service_request}/${request_id}`, {
      method: 'PATCH',
      body: JSON.stringify({
        lessor,
      }),
    });

    if (!result) {
      return null;
    }

    const json = await result.json();
    return json;
  },
  history: async (request_id) => {
    const result = await fetch(
      `${endpoints.service_request}/${request_id}/history`,
    );

    if (!result) {
      return null;
    }

    const json = await result.json();
    return json;
  },
  fetch_history: async (request_id, history_service_request_id) => {
    const result = await fetch(
      `${endpoints.service_request}/${request_id}/history`,
      {
        method: 'POST',
        body: JSON.stringify({
          history_service_request_id,
        }),
      },
    );

    if (!result) {
      return null;
    }

    const json = await result.json();
    return json;
  },
  fetch_important_notification: async () => {
    const result = await fetch(
      `${endpoints.service_request}/important_notification`,
    );

    if (result?.status !== 200) {
      return null;
    }

    const json = await result.json();
    return json;
  },
  most_used_rob_codes: async (request_id) => {
    const result = await fetch(
      `${endpoints.service_request}/${request_id}/most_used/rob_codes`,
    );

    if (!result) {
      return null;
    }

    const json = await result.json();
    return json;
  },
  expired: async () => {
    const result = await fetch(`${endpoints.service_request}/expired`);

    if (result?.status !== 200) {
      return null;
    }

    const json = await result.json();
    return json;
  },
  part_specifications: async (request_id, itemMpId) => {
    const result = await fetch(
      `${endpoints.service_request}/${request_id}/part_specifications/${itemMpId}`,
    );

    if (!result) {
      return null;
    }

    const json = await result.json();
    return json;
  },
  update_part_specifications: async (
    request_id,
    itemMpId,
    task_part_id,
    changes,
  ) => {
    const result = await fetch(
      `${endpoints.service_request}/${request_id}/part_specifications/${itemMpId}`,
      {
        method: 'PATCH',
        body: JSON.stringify({
          task_part_id,
          changes,
        }),
      },
    );

    if (!result) {
      return null;
    }

    const json = await result.json();
    return json;
  },
  sms: async (request_id, phone_number, message) => {
    const result = await fetch(
      `${endpoints.service_request}/${request_id}/client_message`,
      {
        method: 'POST',
        body: JSON.stringify({
          phone_number,
          message,
        }),
      },
    );

    if (!result) {
      return null;
    }

    const json = await result.json();
    return json;
  },
  email: async (request_id, email) => {
    const result = await fetch(
      `${endpoints.service_request}/${request_id}/client_message`,
      {
        method: 'POST',
        body: JSON.stringify({
          email,
        }),
      },
    );

    if (!result) {
      return null;
    }

    const json = await result.json();
    return json;
  },
  client_message_statuses: async (request_id, references) => {
    const result = await fetch(
      `${endpoints.service_request}/${request_id}/client_message/status`,
      {
        method: 'POST',
        body: JSON.stringify(references),
      },
    );

    if (!result) {
      return null;
    }

    const json = await result.json();
    return json;
  },
  dealer_sla_sets: async (request_id) => {
    const result = await fetch(
      `${endpoints.service_request}/${request_id}/sla/set`,
    );

    if (!result) {
      return null;
    }

    const json = await result.json();
    return json;
  },
  update_sla_set: async (request_id, set_id) => {
    const result = await fetch(
      `${endpoints.service_request}/${request_id}/sla/set`,
      {
        method: 'PATCH',
        body: JSON.stringify({
          set_id,
        }),
      },
    );

    if (!result) {
      return null;
    }

    const json = await result.json();
    return json;
  },
  invoice_pdf: async (request_id) => {
    const result = await fetch(
      `${endpoints.service_request}/${request_id}/invoice/pdf`,
    );

    if (!result) {
      return null;
    }

    const blob = await result.blob();
    return new Blob([blob], {type: 'application/pdf'});
  },
  enable_tecrmi: async (request_id) => {
    const result = await fetch(
      `${endpoints.service_request}/${request_id}/enable/tecrmi`,
      {
        method: 'POST',
      },
    );

    if (!result) {
      return null;
    }

    const json = await result.json();
    return json;
  },
};

export default service_request;
